import { destroy, get } from './index';

const incidentreportEndpoint = '/incidentreport';
export const IncidentReportApi = {
  index: () => get(incidentreportEndpoint),
  latest: (amount: number) => get(`${incidentreportEndpoint}/Latest/${amount}`),
  countsPerDay: () => get(`${incidentreportEndpoint}/CountsPerDay/`),
  statistics: () => get(`${incidentreportEndpoint}/Statistics/`),
  remove: (id: string) => destroy(`${incidentreportEndpoint}/${id}`),
  single: (id: any) => get(`${incidentreportEndpoint}/${id}`)
  // create: (params: any) => post(incidentreportEndpoint, params),
  // update: (id: string, params: any) => put(`${incidentreportEndpoint}/${id}`, params),
};
