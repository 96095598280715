import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useWidgetStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export default function Widget(props: any) {
  const classes = useWidgetStyles();

  return (
    <Card className={classes.card} style={props.style}>
      <CardHeader title={props.title} />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
}
