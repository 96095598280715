class NotificationService {
  private Subscribers: Array<any> = [];

  public dispatch = (data: any) => {
    this.Subscribers[0](data);
  };

  public subcribe = (callback: any) => {
    this.Subscribers = [];
    this.Subscribers.push(callback);
  };
}

export default new NotificationService();
