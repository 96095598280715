import { Button } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import Layout from '../../components/Layout';
import Widget from '../../components/Widget';
import { FileApi } from '../../config/api/FileApi';

class ExportPage extends React.PureComponent<RouteComponentProps, {}> {
  render() {
    return (
      <Layout title="Exports">
        <Widget title="Export Data" style={{ flex: 1 }}>
          <Button variant="contained" color="primary" onClick={FileApi.exportToExcel}>
            EXPORT NAAR Excel
          </Button>
          {/* <Button variant="contained" color="secondary" onClick={FileApi.exportToExcelOpenDocument}>
            EXPORT NAAR Excel 2000
          </Button> */}
        </Widget>
      </Layout>
    );
  }
}

export default ExportPage;
