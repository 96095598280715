import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export default class AlertDialog extends React.Component<{}, { open: boolean }> {
  private resolveHandler: any;
  private rejectHandler: any;

  constructor(props: any) {
    super(props);

    this.state = {
      open: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.decline = this.decline.bind(this);
    this.accept = this.accept.bind(this);
  }

  componentDidUpdate() {
    // if(this.props.open !== this.state.open) {
    //   this.setState({open: this.props.open});
    // }
  }

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  public confirm(): Promise<boolean> {
    this.setState({ open: true });

    return new Promise<boolean>((resolve, reject) => {
      this.resolveHandler = resolve;
      this.rejectHandler = reject;
    });
  }

  private handleClose() {
    this.setState({ open: false });
  }

  private decline() {
    this.rejectHandler();
    this.handleClose();
  }

  private accept() {
    this.resolveHandler();
    this.handleClose();
  }

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Verwijder Dispatch Item:</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Weet je zeker dat je dit item wilt verwijderen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.decline()} color="primary">
            Nee
          </Button>
          <Button onClick={() => this.accept()} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
