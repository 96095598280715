import { InputBase, Paper, TableContainer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import AlertDialog from '../../components/dialog/Dialog';
import Layout from '../../components/Layout';
import { UsersApi } from '../../config/api/Users';
import { User } from '../../store/AuthenticationStore';

const useStyles = (theme: any) => ({
  offsetTop: {
    marginTop: 20
  },
  table: {
    minWidth: 650,
    flip: false
  }
});

//type AuthenticationProps = AuthenticationStoreStore.AuthenticationState & typeof AuthenticationStoreStore.actionCreators & RouteComponentProps<{ startDateIndex: string }>;

class Usermanagement extends React.PureComponent<RouteComponentProps, { users: Array<User>; tableRef: any }> {
  private history: any;
  // export default class Dashboard extends React.Component<{}> {
  private classes: any = {};

  constructor(props: any) {
    super(props);
    this.history = this.props.history;

    this.state = {
      users: [],
      tableRef: React.createRef()
    };

    this.getUsers = this.getUsers.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  public componentDidMount() {
    this.getUsers();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {}

  private getUsers(): any {
    UsersApi.index().then((result: any) => {
      this.setState({
        users: result
      });
      return result;
    });
  }

  private UpdateTable(Data: User): object {
    let createUser = {
      firstName: Data.firstName,
      lastName: Data.lastName,
      userName: Data.userName,
      password: Data.password
    };
    return createUser;
  }

  private deleteUser(row: any): void {
    (this.refs.confimrDelete as AlertDialog).confirm().then(() => {
      UsersApi.remove(row.id).then((result: any) => {
        window.location.reload(true);
      });
    });
  }

  public render() {
    return (
      <Layout title="Gebruikers gegevens">
        <AlertDialog ref="confirmDelete" />
        <TableContainer component={Paper}>
          <MaterialTable
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let Create = this.UpdateTable(newData);
                      UsersApi.create(Create);
                    }
                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      let Update = this.UpdateTable(newData);
                      UsersApi.update(newData.id, Update);
                    }
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    UsersApi.remove(oldData.id);
                    resolve();
                  }, 1000);
                })
            }}
            title="Gebruikers"
            localization={{
              toolbar: { searchPlaceholder: 'Zoeken' }
            }}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              filtering: true
            }}
            columns={[
              { field: 'id', title: 'id', hidden: true },
              { field: 'firstName', title: 'Voornaam' },
              { field: 'lastName', title: 'Achternaam' },
              { field: 'userName', title: 'Email adres' },
              { field: 'password', title: 'Wachtwoord', render: (rowData) => <InputBase type="password" disabled value={rowData.password} /> },
              { field: 'roleName', title: 'Rol', initialEditValue: 'User', editable: 'never' },
              { field: 'roleId', title: 'roleId', hidden: true }
            ]}
            data={this.state.users}
          />
        </TableContainer>
      </Layout>
    );
  }
}

export default withStyles(useStyles)(withRouter(Usermanagement));
