import { Action, Reducer } from 'redux';
import { IncidentReportApi } from '../config/api/IncidentReportApi';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IncidentReportState {
  startDateIndex?: number;
  isLoading: boolean;
  incidents: IncidentReport[];
}

export interface IncidentReport {
  startDateIndex?: number;
  id: string;
  image: string;
  createdAt: string;
  message: string;
  place: string;
  latitude?: string;
  longitude?: string;
  priority?: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface RequestIncidentReportsAction {
  type: 'REQUEST_INCIDENT_REPORTS';
  startDateIndex: number;
}

interface ReceiveIncidentReportsAction {
  type: 'RECEIVE_INCIDENT_REPORTS';
  startDateIndex: number;
  incidents: IncidentReport[];
}

export const actionCreators = {
  requestIncidentReports: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.incidentReports && startDateIndex !== appState.incidentReports.startDateIndex) {
      dispatch({ type: 'REQUEST_INCIDENT_REPORTS', startDateIndex: startDateIndex });

      IncidentReportApi.latest(10).then((incidents) => {
        dispatch({ type: 'RECEIVE_INCIDENT_REPORTS', startDateIndex: startDateIndex, incidents: incidents });
      });
    }
  }
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestIncidentReportsAction | ReceiveIncidentReportsAction;

const unloadedState: IncidentReportState = { incidents: [], isLoading: false };

export const reducer: Reducer<IncidentReportState> = (state: IncidentReportState | undefined, incomingAction: Action): IncidentReportState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_INCIDENT_REPORTS':
      return {
        startDateIndex: action.startDateIndex,
        incidents: state.incidents,
        isLoading: true
      };
    case 'RECEIVE_INCIDENT_REPORTS':
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      if (action.startDateIndex === state.startDateIndex) {
        return {
          startDateIndex: action.startDateIndex,
          incidents: action.incidents,
          isLoading: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};
