export interface HttpRequest {
  headers: {};
  method: string;
  body?: string;
}

class ApiClient {
  static create() {
    return new ApiClientWrapper();
  }

  static configure(uriPrefix: string, dispatch: any) {
    apiClientWrapper.configure(uriPrefix, dispatch);
  }
}

class ApiClientWrapper {
  private uriPrefix: string = '';
  private dispatch: any;
  private internalInterceptors: Array<HttpRequest> = [];

  public configure(uriPrefix: string, dispatch: any) {
    if (!uriPrefix.endsWith('/')) {
      uriPrefix = `${uriPrefix}/`;
    }
    this.uriPrefix = uriPrefix;
    this.dispatch = dispatch;
  }

  public get = async (uri: string) => {
    let settings: HttpRequest = {
      method: 'GET',
      headers: {}
    };
    settings.headers = this.parseInterceptors(settings);

    let apiUri = this.parseUri(uri);

    const fetchResponse = await fetch(apiUri, settings).then((response) => {
      if (!response.ok) {
        this.dispatch(response.statusText);
      }
      return response;
    });
    return fetchResponse.json();
  };

  public post = async (uri: string, params: any) => {
    let settings: HttpRequest = {
      method: 'POST',
      headers: {}
    };

    settings.headers = this.parseInterceptors(settings);
    settings.body = JSON.stringify(params);

    let apiUri = this.parseUri(uri);

    const fetchResponse = await fetch(apiUri, settings)
      .then(async (response) => {
        if (!response.ok) {
          try {
            let body = await response.json();
            this.dispatch(body);
          } catch (err) {
            this.dispatch(`Error: ${response.statusText}`);
          }
        } else {
          let body = await response.json();
          return body;
        }
      })
      .catch((response) => {
        this.dispatch(response);
      });
    return fetchResponse;
  };

  public put = async (uri: string, params: any) => {
    let settings: HttpRequest = {
      method: 'PUT',
      headers: {}
    };
    settings.headers = this.parseInterceptors(settings);
    settings.body = JSON.stringify(params);

    let apiUri = this.parseUri(uri);

    const fetchResponse = await fetch(apiUri, settings)
      .then(async (response) => {
        if (!response.ok) {
          let body = await response.json();
          this.dispatch(body ? body : response.statusText);
        } else {
          let body = await response.json();
          return body;
        }
      })
      .catch((response) => {
        this.dispatch(response);
      });
    return fetchResponse;
  };

  public destroy = async (uri: string): Promise<any> => {
    let settings: HttpRequest = {
      method: 'DELETE',
      headers: {}
    };
    settings.headers = this.parseInterceptors(settings);

    let apiUri = this.parseUri(uri);

    const fetchResponse = await fetch(apiUri, settings).then((response) => {
      if (!response.ok) {
        this.dispatch(response.statusText);
      }
      return response;
    });
    return fetchResponse;
  };

  public download = async (uri: string, filename: string) => {
    let settings: HttpRequest = {
      method: 'GET',
      headers: {}
    };
    settings.headers = this.parseInterceptors(settings);

    let apiUri = this.parseUri(uri);

    return await fetch(apiUri, settings).then((response: any) => {
      response.blob().then((blob: any) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      });
      return response;
    });
  };

  public interceptors = {
    request: {
      use: (interceptor: any) => {
        if (interceptor !== undefined && typeof interceptor === 'function') {
          this.internalInterceptors.push(interceptor);
        }
      }
    }
  };

  private parseUri(uri: string): string {
    uri = uri.replace(/\/\//g, '/');
    if (this.uriPrefix.endsWith('/') && uri.startsWith('/')) {
      this.uriPrefix = this.uriPrefix.substr(0, this.uriPrefix.length - 1);
    }
    let apiUri = `${this.uriPrefix}${uri}`;

    return apiUri;
  }

  private parseInterceptors(settings: HttpRequest): HttpRequest {
    let headers: any = {};
    this.internalInterceptors.forEach((interceptor: any) => {
      try {
        let interceptorResult = interceptor(settings);
        headers = Object.assign(settings.headers, interceptorResult.headers);
      } catch (err) {}
    });

    return headers;
  }
}

const apiClientWrapper = ApiClient.create();
apiClientWrapper.interceptors.request.use(
  (config: HttpRequest) => {
    return {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    };
  }
  // error => Promise.reject(error),
);

apiClientWrapper.interceptors.request.use(
  (config: HttpRequest) => {
    const token = window.localStorage.getItem('authToken');
    if (token !== null) {
      return {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }

    return {};
  }
  // error => Promise.reject(error),
);

const { get, post, put, destroy, download } = apiClientWrapper;
export { get, post, put, destroy, download, ApiClient };
