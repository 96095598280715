import { Box, CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IncidentReportApi } from '../../config/api/IncidentReportApi';

const useStyles = (theme: any) => ({
  offsetTop: {
    marginTop: 20
  },
  table: {
    minWidth: 650,
    flip: false
  },
  image: {
    maxWidth: '100%',
    maxHeight: '600px'
  }
});

class IncidentImagePage extends React.PureComponent<RouteComponentProps & { classes: any }, { image: string; loading: boolean }> {
  private history: any;
  private classes: any = {};
  private incidentId: string;

  constructor(props: any) {
    super(props);
    this.classes = props.classes;
    this.history = this.props.history;

    const params: any = this.props.match.params;
    this.incidentId = params.id;

    this.state = {
      image: '',
      loading: true
    };
  }

  public componentDidMount() {
    this.setState({ loading: true });
    try {
      IncidentReportApi.single(`${this.incidentId}/image`)
        ?.then((result) => {
          var imageData = `data:image/jpeg;base64,${result}`;
          this.setState({ image: imageData });
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } catch (err) {
      // do nothing
      this.setState({ loading: false });
    }
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {}

  public render() {
    return (
      <Box m={3} display="flex" justifyContent="center">
        {this.state.loading ? (
          <>
            <CircularProgress color="primary" />
          </>
        ) : (
          <>{this.state.image ? <img src={this.state.image} className={this.classes.image} alt="" /> : <Typography variant="body1">Image not found</Typography>}</>
        )}
      </Box>
    );
  }
}

export default withStyles(useStyles)(withRouter(IncidentImagePage));
