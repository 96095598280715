import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';
import NotificationService from '../services/notificationService';

export default class CustomizedSnackbars extends React.Component<{}, { open: boolean; notification: Array<JSX.Element> }> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
      notification: []
    };

    this.handleClose = this.handleClose.bind(this);
    this.addAlert = this.addAlert.bind(this);
    NotificationService.subcribe(this.addAlert);
  }

  private handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  private addAlert = (type: any) => {
    if (type.errors !== undefined && type.errors !== null) {
      let value = Object.values(type.errors);
      let message = value.map((item: any, i) => {
        return <li key={i}>{item[0]}</li>;
      });
      this.setState({ open: true, notification: message });
    }
    if (type.message !== undefined) {
      this.setState({ open: true, notification: type.message });
    }
  };

  public Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  render() {
    return (
      <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <this.Alert onClose={this.handleClose} severity="error">
          {this.state.notification && this.state.notification}
        </this.Alert>
      </Snackbar>
    );
  }
}
