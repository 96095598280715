import { post } from './index';

export interface AuthenticationParams {
  username: string;
  password: string;
}

export const Authentication = {
  login: (params: any) => post('/users/authenticate', params)
};
