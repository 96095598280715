import { destroy, get, post, put } from './index';

const endpoint = '/users';
export const UsersApi = {
  index: () => get(endpoint),
  single: (id: any) => get(`/users/${id}`),
  singleByEmail: (email: string) => get(`/users?email=${email}`),
  create: (params: any) => post(endpoint, params),
  update: (id: string, params: any) => put(`${endpoint}/${id}`, params),
  remove: (id: string) => destroy(`${endpoint}/${id}`)
};
