import { destroy, get, post, put } from './index';

const endpoint = '/dispatch';
export const DispatchApi = {
  index: () => get(endpoint),
  // single: (id: any) => get(`${incidentreportEndpoint}/${id}`),
  create: (params: any) => post(endpoint, params),
  update: (id: string, params: any) => put(`${endpoint}/${id}`, params),
  remove: (id: string) => destroy(`${endpoint}/${id}`)
};
