import { Paper, TableContainer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/Layout';
import { CategoryApi } from '../../config/api/category';
import { DispatchApi } from '../../config/api/DispatchApi';
import AlertDialog from './../../components/dialog/Dialog';

const useStyles = (theme: any) => ({
  offsetTop: {
    marginTop: 20
  },
  table: {
    minWidth: 650,
    flip: false
  }
});

interface Item {
  id: string;
  emailAddress: string;
  postalCodeFrom?: string;
  postalCodeTo?: string;
  locality?: string;
  categoryId: string;
  categoryName: string;
}

interface Categories {
  id: string;
  name: string;
}

class DispatchPage extends React.PureComponent<RouteComponentProps, { items: Array<Item>; tableRef: any; category: Array<Categories>; catList: Object }> {
  private history: any;
  // export default class Dashboard extends React.Component<{}> {
  private classes: any = {};

  constructor(props: any) {
    super(props);

    this.history = this.props.history;
    this.state = {
      items: [],
      tableRef: React.createRef(),
      category: [],
      catList: {}
    };

    this.getItems = this.getItems.bind(this);
  }

  public componentDidMount() {
    this.getCat();
    this.getItems();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {}

  private getCat(): void {
    CategoryApi.index().then((result: Array<Categories>) => {
      let categories: Array<Categories> = [];
      result.forEach((element) => {
        categories.push({ id: element.id, name: element.name });
      });
      this.setState({
        category: categories
      });
      this.getCategoryList(categories);
    });
  }
  private getItems(): void {
    DispatchApi.index().then((result) => {
      result = this.getCategoryName(result);
      this.setState({
        items: result
      });
    });
  }

  private makeCreate(Data: Item): object {
    let create = {
      id: Data.id,
      locality: Data.locality,
      postalCodeFrom: Data.postalCodeFrom,
      postalCodeTo: Data.postalCodeTo,
      emailAddress: Data.emailAddress,
      categoryId: Data.categoryId,

      categorie: this.state.category.forEach((element) => {
        if (element.id === Data.categoryId) return element.name;
        else return null;
      })
    };
    return create;
  }

  private getCategoryName(items: Array<Item>) {
    items.forEach((element) => {
      for (let i = 0; i < this.state.category.length; i++) {
        if (this.state.category[i].id === element.categoryId) {
          element.categoryName = this.state.category[i].name;
        }
      }
    });
    return items;
  }
  private getCategoryList(categories: Array<Categories>): void {
    categories.map((element) => {
      return this.setState((prevState) => ({
        catList: { ...prevState.catList, [element.id]: element.name }
      }));
    });
  }

  render() {
    return (
      <Layout title="Dispatch">
        <AlertDialog ref="confirmDelete" />
        <TableContainer component={Paper}>
          <MaterialTable
            tableRef={this.state.tableRef}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    {
                      let create = this.makeCreate(newData);
                      await DispatchApi.create(create);
                      this.getItems();
                    }
                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    {
                      let create = this.makeCreate(newData);
                      await DispatchApi.update(newData.id, create);
                      this.getItems();
                    }
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    await DispatchApi.remove(oldData.id);
                    this.getItems();
                    resolve();
                  }, 1000);
                })
            }}
            title="Dispatch"
            localization={{
              toolbar: { searchPlaceholder: 'Zoeken' }
            }}
            options={{
              actionsColumnIndex: -1,
              search: true,
              filtering: true,
              paging: false,
              addRowPosition: 'first'
            }}
            columns={[
              { title: 'Key', field: 'id', hidden: true, searchable: false },
              { title: 'Plaats', field: 'locality' },
              { title: 'Postcode van', field: 'postalCodeFrom' },
              { title: 'Postcode tot', field: 'postalCodeTo' },
              { title: 'Email adres', field: 'emailAddress' },
              { title: 'Categorie', field: 'categoryId', lookup: this.state.catList },
              { title: 'CategorieID', field: 'categoryId', hidden: true }
            ]}
            data={this.state.items}
          />
        </TableContainer>
      </Layout>
    );
  }
}

export default withStyles(useStyles)(withRouter(DispatchPage));
