import Box from '@material-ui/core/Box';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import IncidentReports from '../../components/IncidentReports';
import Layout from '../../components/Layout';
import Settings from '../../services/AppSettings';
import { ApplicationState } from '../../store';
import * as AuthenticationStoreStore from '../../store/AuthenticationStore';
import Widget from './../../components/Widget';
import MessageCountChart from './components/MessageCountChart';
import MobileOsChart from './components/MobileOsChart';

/*
 *
 * Create your page using the above tables
 *
 */
type AuthenticationProps = AuthenticationStoreStore.AuthenticationState & typeof AuthenticationStoreStore.actionCreators & RouteComponentProps<{ startDateIndex: string }>;

class Dashboard extends React.PureComponent<AuthenticationProps, { email: string; password: string }> {
  private history: any;
  // export default class Dashboard extends React.Component<{}> {
  private classes: any = {};

  constructor(props: any) {
    super(props);
    this.history = this.props.history;

    // this.classes = useStyles();
  }

  public componentDidMount() {
    this.ensureLoggedIn();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureLoggedIn();
  }

  private ensureLoggedIn() {
    const startDateIndex = 0;

    this.props.requestAuthenticated(startDateIndex);

    if (!this.props.isAuthenticated) {
      if (this.history) this.history.push('/');
    }
  }
  render() {
    return (
      <Layout title={'Dashboard ' + Settings.Environment}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Widget title="Meldingen per dag" style={{ flex: 3 }}>
            <MessageCountChart />
          </Widget>
          <Widget title="Telefoon verdeling" style={{ flex: 1 }}>
            <MobileOsChart />
          </Widget>
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Widget title="Laatste meldingen" style={{ flex: 1 }}>
            <IncidentReports />
          </Widget>
        </Box>
      </Layout>
    );
  }
}

export default connect((state: ApplicationState) => state.authentication, AuthenticationStoreStore.actionCreators)(withRouter(Dashboard) as any);
