import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationState } from '../store';
import * as AuthenticationStoreStore from '../store/AuthenticationStore';

type AuthenticationProps = AuthenticationStoreStore.AuthenticationState & typeof AuthenticationStoreStore.actionCreators & RouteComponentProps<{ startDateIndex: string }>;

class Logout extends React.PureComponent<AuthenticationProps, {}> {
  private history: any;

  constructor(props: any) {
    super(props);
    this.history = this.props.history;
  }

  public componentDidMount() {
    this.ensureLoggedOut();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureLoggedOut();
  }

  private ensureLoggedOut() {
    this.props.signOut();

    if (!this.props.isAuthenticated) {
      if (this.history) this.history.push('/');
    }
  }

  public render() {
    return (
      <Container component="main">
        <p>Logout!</p>
      </Container>
    );
  }
}

export default connect((state: ApplicationState) => state.authentication, AuthenticationStoreStore.actionCreators)(withRouter(Logout) as any);
