import { Action, Reducer } from 'redux';
import { UsersApi } from '../config/api/Users';
import { AppThunkAction } from './';
import { Authentication } from './../config/api/authentication';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  role: string;
  password: string;
  roleId: string;
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface AuthenticationState {
  Message: string;
  startDateIndex: number;
  isLoading: boolean;
  isAuthenticated: boolean;
  token?: string;
  user?: User;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface RequestAuthenticationAction {
  type: 'REQUEST_AUTHENTICATED';
  startDateIndex: number;
}
interface ReceiveAuthenticationAction {
  type: 'RECEIVE_AUTHENTICATED';
  startDateIndex: number;
  data: any;
}

interface RequestLoginAction {
  type: 'REQUEST_LOGIN';
  startDateIndex: number;
}

interface RequestSignoutAction {
  type: 'REQUEST_SIGNOUT';
}

interface BadRequest {
  type: 'BAD REQUEST';
  startDateIndex: number;
  data: any;
}

export const actionCreators = {
  requestAuthenticated: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.authentication && startDateIndex !== appState.authentication.startDateIndex) {
      // console.log('requestAuthenticated -> appState: ', appState);

      dispatch({ type: 'REQUEST_AUTHENTICATED', startDateIndex: startDateIndex });

      const uid = window.localStorage.getItem('uid');
      if (uid !== undefined && uid !== null && uid !== 'undefined') {
        UsersApi.single(uid)
          .then((userResult: any) => {
            if (userResult !== undefined && userResult !== null) {
              dispatch({
                type: 'RECEIVE_AUTHENTICATED',
                startDateIndex: startDateIndex,
                data: {
                  isAuthenticated: true,
                  token: window.localStorage.getItem('authToken'),
                  id: userResult.id,
                  firstName: userResult.firstName,
                  lastName: userResult.lastName,
                  userName: userResult.userName,
                  password: userResult.password,
                  role: userResult.roleName,
                  roleId: userResult.roleId
                }
              });
            } else {
              dispatch({ type: 'RECEIVE_AUTHENTICATED', startDateIndex: startDateIndex, data: { isAuthenticated: false } });
            }
          })
          .catch((err: any) => {
            dispatch({ type: 'RECEIVE_AUTHENTICATED', startDateIndex: startDateIndex, data: { isAuthenticated: false } });
          });
      } else {
        dispatch({ type: 'RECEIVE_AUTHENTICATED', startDateIndex: startDateIndex, data: { isAuthenticated: false } });
      }
    }
  },
  signOut: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.authentication) {
      window.localStorage.clear();
      dispatch({ type: 'REQUEST_SIGNOUT' });
    }
  },
  login: (username: string, password: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState && appState.authentication && username !== '' && password !== '') {
      dispatch({ type: 'REQUEST_LOGIN', startDateIndex: 0 });

      const startDateIndex = ++appState.authentication.startDateIndex;
      Authentication.login({ username: username, password: password })
        .then((authResult) => {
          if (authResult !== undefined && authResult !== null && authResult.token !== undefined) {
            window.localStorage.setItem('authToken', authResult.token);
            window.localStorage.setItem('uid', authResult.id);

            authResult.isAuthenticated = true;
            dispatch({ type: 'RECEIVE_AUTHENTICATED', startDateIndex: startDateIndex, data: authResult });
          } else {
            dispatch({ type: 'BAD REQUEST', startDateIndex: startDateIndex, data: { isAuthenticated: false } });
          }
        })
        .catch((err) => {
          dispatch({ type: 'BAD REQUEST', startDateIndex: startDateIndex, data: { isAuthenticated: false } });
        });
    }
  },
  badRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'REQUEST_SIGNOUT' });
  }
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAuthenticationAction | ReceiveAuthenticationAction | RequestLoginAction | RequestSignoutAction | BadRequest;

const unloadedState: AuthenticationState = { Message: '', isAuthenticated: false, isLoading: false, startDateIndex: -1 };

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState | undefined, incomingAction: Action): AuthenticationState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_AUTHENTICATED':
      return {
        Message: 'Request authenticated',
        startDateIndex: action.startDateIndex,
        isAuthenticated: false,
        isLoading: true
      };
    case 'RECEIVE_AUTHENTICATED':
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      if (action.startDateIndex === state.startDateIndex) {
        return {
          Message: 'Receive authenticated',
          startDateIndex: action.startDateIndex,
          isLoading: false,
          isAuthenticated: action.data.isAuthenticated,
          token: action.data.token,
          user: {
            id: action.data.id,
            firstName: action.data.firstName,
            lastName: action.data.lastName,
            userName: action.data.userName,
            password: action.data.password,
            role: action.data.roleName,
            roleId: action.data.roleId
          }
        };
      }
      break;
    case 'REQUEST_LOGIN':
      return {
        Message: 'Request login',
        startDateIndex: action.startDateIndex,
        isAuthenticated: false,
        isLoading: true
      };
    case 'REQUEST_SIGNOUT':
      return {
        Message: 'Request signout',
        startDateIndex: -1,
        isAuthenticated: false,
        isLoading: false,
        token: undefined,
        user: undefined
      };
    case 'BAD REQUEST':
      return {
        Message: 'Bad request',
        startDateIndex: action.startDateIndex,
        isAuthenticated: false,
        isLoading: false
      };
    default:
      return state;
  }
  return state;
};
