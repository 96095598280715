import { Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as IncidentReportStore from '../store/IncidentReportStore';

type IncidentReportProps = IncidentReportStore.IncidentReportState & typeof IncidentReportStore.actionCreators & RouteComponentProps<{ startDateIndex: string }>;

class IncidentReports extends React.PureComponent<IncidentReportProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  private parseDate(date: Date, format: string = 'dd-mm-yyyy hh:ii'): string {
    // console.log('parseDate: ', date);
    format = format.replace('yyyy', date.getFullYear().toString());
    format = format.replace('mm', ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace('dd', ('0' + date.getDate()).slice(-2));
    format = format.replace('hh', ('0' + date.getHours()).slice(-2));
    format = format.replace('ii', ('0' + date.getMinutes()).slice(-2));
    format = format.replace('ss', ('0' + date.getSeconds()).slice(-2));
    format = format.replace('u', ('0' + date.getMilliseconds()).slice(-2));

    return format;
  }

  public render() {
    return <React.Fragment>{this.renderIncidentReportTable()}</React.Fragment>;
  }

  private ensureDataFetched() {
    // const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
    const startDateIndex = 0;
    this.props.requestIncidentReports(startDateIndex);
  }

  private renderIncidentReportTable() {
    return (
      <Box>
        <Table className="table table-striped" aria-labelledby="tabelLabel">
          <TableHead>
            <TableRow>
              <th>Datum/Tijd</th>
              <th>Bericht</th>
              <th>Afbeelding</th>
              <th>Plaats</th>
              <th>Status</th>
              <th>Acties</th>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.incidents.map((incidentReport: IncidentReportStore.IncidentReport) => {
              let dateTime = this.parseDate(new Date(Date.parse(incidentReport.createdAt))) || '';

              return (
                <TableRow key={incidentReport.id}>
                  <td>{dateTime}</td>
                  <td>{incidentReport.message}</td>
                  <td>{incidentReport.image}</td>
                  <td>{incidentReport.place}</td>
                  <td></td>
                  <td>
                    <DeleteIcon color="error" />
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

export default connect((state: ApplicationState) => state.incidentReports, IncidentReportStore.actionCreators)(IncidentReports as any);
