import { AppBar, Button, CssBaseline, Drawer, makeStyles, Snackbar, Theme, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';
import { withRouter } from 'react-router';
import SideMenu from './SideMenu';

const logo = require('./../assets/waaksamenlogo.png');

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#22222f'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  title: {
    fontFamily: 'Asap,sans-serif',
    fontWeight: 500,
    color: '#22222f',
    marginBottom: '2rem'
  },
  apptitle: {
    flexGrow: 1
  }
}));

const Layout = (props: any) => {
  console.log('props: ', props);
  const classes = useStyles();
  const history = props.history;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <img style={{ height: '40px', marginRight: '1rem' }} src={logo} alt="logo" />
          <Typography variant="h6" className={classes.apptitle}>
            <span style={{ fontWeight: 700 }}>waak</span>samen BackOffice
          </Typography>
          <Button color="inherit" onClick={() => history.push('/logout')}>
            Uitloggen
          </Button>
        </Toolbar>
      </AppBar>
      <Snackbar />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <SideMenu />
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Typography component="h4" variant="h4" className={classes.title}>
          {props.title}
        </Typography>
        {props.children}
      </main>
    </div>
  );
};

export default withRouter(Layout);
