import Typography from '@material-ui/core/Typography';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}{' '}
      <Router>
        <br />
        <a color="secondary" href="https://www.vcsobservation.com/vcs-solutions/waaksamen/" target="_blank" rel="noopener noreferrer">
          VCS Observation
        </a>
      </Router>
      {'.'}
    </Typography>
  );
}
