import { Paper, TableContainer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/Layout';
import { LogApi } from '../../config/api/LogApi';

const useStyles = (theme: any) => ({
  offsetTop: {
    marginTop: 20
  },
  table: {
    minWidth: 650,
    flip: false
  }
});

interface LogDetail {
  level: string;
  message: string;
  createdAt: string;
  event: string;
  logger: string;
}

class LogPage extends React.PureComponent<RouteComponentProps, { logs: LogDetail[]; tableRef: any }> {
  private history: any;
  private classes: any = {};

  constructor(props: any) {
    super(props);
    this.history = this.props.history;

    this.state = {
      logs: [],
      tableRef: React.createRef()
    };

    this.getLogs = this.getLogs.bind(this);
  }

  public componentDidMount() {
    this.getLogs();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {}

  private getLogs(): any {
    LogApi.index().then((result: []) => {
      this.setState({
        logs: result
      });
      return result;
    });
  }

  public render() {
    return (
      <Layout title="Logging">
        <TableContainer component={Paper}>
          <MaterialTable
            title="Laatste 100 logregels"
            localization={{
              toolbar: { searchPlaceholder: 'Zoeken' }
            }}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              filtering: true
            }}
            columns={[
              { field: 'createdAt', title: 'Aanmaakdatum', type: 'datetime' },
              { field: 'level', title: 'Level' },
              { field: 'event', title: 'Event' },
              { field: 'logger', title: 'Logger' },
              { field: 'message', title: 'Message' }
            ]}
            data={this.state.logs}
          />
        </TableContainer>
      </Layout>
    );
  }
}

export default withStyles(useStyles)(withRouter(LogPage));
