import React from 'react';
import { Route } from 'react-router';
import { ApiClient } from './config/api';
import './custom.css';
import Dashboard from './pages/dashboard';
import DispatchPage from './pages/dispatch/index';
import ExportPage from './pages/export/index';
import IncidentImagePage from './pages/incidents/IncidentImage';
import IncidentPage from './pages/incidents/index';
import LogPage from './pages/logging/index';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Usermanagement from './pages/usermanagement/index';
import Settings from './services/AppSettings';
import NotificationService from './services/notificationService';

ApiClient.configure(Settings.ApiEndpoint!, NotificationService.dispatch);

export default () => (
  <React.Fragment>
    <Route exact path="/" component={Login} />
    {/* 
      <Layout> 
    */}
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/dispatch" component={DispatchPage} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/users" component={Usermanagement} />
    <Route exact path="/export" component={ExportPage} />
    <Route exact path="/incidents" component={IncidentPage} />
    <Route exact path="/incident/image/:id" component={IncidentImagePage} />
    <Route exact path="/logs" component={LogPage} />
    {/* 
      </Layout> 
    */}
  </React.Fragment>
);
