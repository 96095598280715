import React from 'react';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import { IncidentReportApi } from '../../../config/api/IncidentReportApi';

interface labelOptions {
  fill: string;
  stroke: string;
  percent: number;
  cornerRadius: any;
  name: string;
  midAngle: number;
  middleRadius: number;
  value: any;
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  maxRadius: number;
  startAngle: number;
  endAngle: number;
  index: number;
  textAnchor: string;
  x: number;
  y: number;
}

interface statistic {
  name: string;
  value: number;
}

interface MyState {
  statistics: statistic[];
}

export default class MobileOsChart extends React.PureComponent<{ classes?: any }, MyState> {
  private _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      statistics: []
    };
  }
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/';

  private renderCustomizedLabel(options: any): any {
    options = options as labelOptions;

    const RADIAN = Math.PI / 180;
    const radius = options.innerRadius + (options.outerRadius - options.innerRadius) * 0.5;
    const x = options.cx + radius * Math.cos(-options.midAngle * RADIAN);
    const y = options.cy + radius * Math.sin(-options.midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > options.cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(options.percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    this._isMounted = true;
    this.ensureDataFetched();
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    return <React.Fragment>{this.renderDayCountTable()}</React.Fragment>;
  }

  private ensureDataFetched() {
    IncidentReportApi.statistics().then((result) => {
      let oslist = [];
      oslist.push({ name: 'Android', value: result.osAndroid });
      oslist.push({ name: 'iOS', value: result.osios });
      oslist.push({ name: 'Onbekend', value: result.osUnknown });
      if (this._isMounted) {
        this.setState({
          statistics: oslist
        });
      }
    });
  }

  private renderDayCountTable() {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    return (
      <div>
        <PieChart width={200} height={250}>
          <Pie data={this.state.statistics} labelLine={false} label={this.renderCustomizedLabel} outerRadius={80} fill="#8884d8" dataKey="value">
            {this.state.statistics.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </div>
    );
  }
}
