import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { IncidentReportApi } from '../../../config/api/IncidentReportApi';

interface MyState {
  items: [];
}

class MessageCountChart extends React.Component<{}, MyState> {
  private _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      items: []
    };

    this.ensureDataFetched = this.ensureDataFetched.bind(this);
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    this._isMounted = true;
    this.ensureDataFetched();
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private ensureDataFetched() {
    IncidentReportApi.countsPerDay().then((result: any) => {
      if (this._isMounted) {
        this.setState({
          items: result
        });
      }
    });
  }

  public render() {
    return (
      <BarChart width={730} height={250} data={this.state.items} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar name="Persoon" dataKey="statistics.person" fill="#f50057" stackId="a" />
        <Bar name="Groep" dataKey="statistics.group" fill="#006be5" stackId="a" />
        <Bar name="Voertuig" dataKey="statistics.vehicle" fill="#009954" stackId="a" />
        <Bar name="Ondermijning" dataKey="statistics.neighbhourhood" fill="#ff8f00" stackId="a" />
        <Legend />
      </BarChart>
    );
  }
}

export default MessageCountChart;
