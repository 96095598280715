import { Paper, TableContainer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React from 'react';
import Moment from 'react-moment';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import AlertDialog from '../../components/dialog/Dialog';
import Layout from '../../components/Layout';
import { IncidentReportApi } from '../../config/api/IncidentReportApi';
import { IncidentReport } from '../../store/IncidentReportStore';

const useStyles = (theme: any) => ({
  offsetTop: {
    marginTop: 20
  },
  table: {
    minWidth: 650,
    flip: false
  }
});

class IncidentPage extends React.PureComponent<RouteComponentProps, { incidents: Array<IncidentReport>; tableRef: any; open: boolean; singleIncident: any }> {
  private history: any;
  private classes: any = {};

  constructor(props: any) {
    super(props);
    this.history = this.props.history;

    this.state = {
      incidents: [],
      tableRef: React.createRef(),
      open: false,
      singleIncident: {}
    };

    this.getIncidents = this.getIncidents.bind(this);
    this.deleteIncident = this.deleteIncident.bind(this);
  }

  public componentDidMount() {
    this.getIncidents();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {}

  private getIncidents(): any {
    IncidentReportApi.index().then((result: IncidentReport[]) => {
      this.setState({
        incidents: result.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
      });
      return result;
    });
  }
  private getSingleIncident(id: string): any {
    IncidentReportApi.single(id).then((result: any) => {
      let deviceInfo = result.deviceInfo ? result.deviceInfo.model : '';
      let place = result.postalCode ? result.place + ' - ' + result.postalCode : result.place;
      let answers = this.getAnswerFromResult(result.answers);

      this.setState({
        singleIncident: { id: result.id, image: result.image, createdAt: new Date(result.createdAt).toDateString(), message: answers, place: place, deviceModel: deviceInfo }
      });
      return result;
    });
  }
  private getAnswerFromResult(part: any): any {
    let answerList = '';
    part.forEach((partTitle: any) => {
      answerList += partTitle.title + ': ';
      partTitle.answers.forEach((partAnswer: any) => {
        answerList += partAnswer + ', ';
      });
      answerList = answerList.substr(0, answerList.length - 2);
      answerList += '\n';
    });
    return answerList;
  }

  private async deleteIncident(row: any): Promise<any> {
    await IncidentReportApi.remove(row.id);
    this.getIncidents();
  }
  private showDetail(row: any): void {
    this.getSingleIncident(row.id);
    this.setState({ open: true });
  }
  private handleClose(): void {
    this.setState({ open: false });
  }
  public render() {
    return (
      <Layout title="Incidenten gemeld">
        <Dialog open={this.state.open} onClose={() => this.handleClose()} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">Melding</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.singleIncident.createdAt}</DialogContentText>
            <DialogContentText>{this.state.singleIncident.place}</DialogContentText>
          </DialogContent>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1} style={{ whiteSpace: 'pre' }}>
              {this.state.singleIncident.message}
            </DialogContentText>
            <DialogContentText>{this.state.singleIncident.image}</DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>{this.state.singleIncident.deviceModel}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()}>Close</Button>
          </DialogActions>
        </Dialog>
        <AlertDialog ref="confirmDelete" />
        <TableContainer component={Paper}>
          <MaterialTable
            editable={{
              onRowDelete: (oldData) => this.deleteIncident(oldData)
            }}
            title="Incidenten"
            localization={{
              toolbar: { searchPlaceholder: 'Zoeken' }
            }}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              filtering: true
            }}
            actions={[
              {
                icon: 'info',
                tooltip: 'Meer detail',
                onClick: (event, rowData) => this.showDetail(rowData)
              }
            ]}
            columns={[
              { field: 'id', title: 'id', hidden: true },
              {
                field: 'createdAt',
                title: 'Aanmaakdatum',
                type: 'datetime',
                render: (row) => <Moment format="DD-MM-YYYY HH:mm">{row.createdAt}</Moment>
              },
              { field: 'postalCode', title: 'Postcode' },
              { field: 'place', title: 'Plaats' },
              { field: 'message', title: 'Bericht' },
              { field: 'image', title: 'Foto' }
            ]}
            data={this.state.incidents}
          />
        </TableContainer>
      </Layout>
    );
  }
}

export default withStyles(useStyles)(withRouter(IncidentPage));
