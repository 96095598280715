import { Box, Button } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Copyright from './Copyright';

class SideMenu extends React.Component<RouteComponentProps> {
  private history: any;

  constructor(props: any) {
    super(props);

    this.history = this.props.history;
  }

  render() {
    return (
      <Box>
        <Box style={{ display: 'flex', position: 'fixed', flexDirection: 'column', alignItems: 'start' }}>
          <Button color="inherit" onClick={() => this.history.push('/dashboard')}>
            Dashboard
          </Button>
          <Button color="inherit" onClick={() => this.history.push('/users')}>
            Gebruikers
          </Button>
          <Button color="inherit" onClick={() => this.history.push('/dispatch')}>
            Dispatch
          </Button>
          <Button color="inherit" onClick={() => this.history.push('/incidents')}>
            Meldingen
          </Button>
          <Button color="inherit" onClick={() => this.history.push('/export')}>
            Export
          </Button>
          <Button color="inherit" onClick={() => this.history.push('/logs')}>
            Logging
          </Button>
        </Box>
        <Box style={{ position: 'fixed', bottom: 0 }}>
          <Copyright />
        </Box>
      </Box>
    );
  }
}

export default withRouter(SideMenu);
