import Box from '@material-ui/core/Box';
// material ui
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
// custom components
import Copyright from '../components/Copyright';
import Snackbar from '../components/Snackbar';
import { ApplicationState } from '../store';
import * as AuthenticationStoreStore from '../store/AuthenticationStore';

const logo = require('./../assets/waaksamenlogo.png');

type AuthenticationProps = AuthenticationStoreStore.AuthenticationState & typeof AuthenticationStoreStore.actionCreators & RouteComponentProps<{ startDateIndex: string }>;

class Login extends React.PureComponent<AuthenticationProps, { email: string; password: string; error: boolean }> {
  private history: any;
  private startDateIndex: number = 0;

  constructor(props: any) {
    super(props);
    this.history = this.props.history;

    this.state = {
      email: '',
      password: '',
      error: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public UNSAFE_componentWillMount() {
    document.body.style.height = '100%';
    document.body.style.display = 'flex';
    document.body.style.alignItems = 'center';
    document.body.style.paddingTop = '40px';
    document.body.style.paddingBottom = '40px';
    document.body.style.justifyContent = 'center';
    document.body.style.backgroundColor = '#22222f';
  }

  public componentWillUnmount() {
    document.body.style.height = '';
    document.body.style.display = '';
    document.body.style.alignItems = '';
    document.body.style.paddingTop = '';
    document.body.style.paddingBottom = '';
    document.body.style.justifyContent = '';
    document.body.style.backgroundColor = '';
  }

  public componentDidMount() {
    this.ensureLoggedIn();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureLoggedIn();
  }

  private ensureLoggedIn() {
    this.props.requestAuthenticated(this.startDateIndex);
    if (this.props.Message === 'Bad request') {
      this.setState({ error: true });
      this.props.badRequest();
    }
    if (this.props.isAuthenticated) {
      this.startDateIndex++;
      if (this.history) this.history.push('/dashboard');
    }
  }

  public render() {
    return <React.Fragment>{this.renderLoginPage()}</React.Fragment>;
  }

  private validateForm(): boolean {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  private handleSubmit(event: any): void {
    this.startDateIndex++;
    this.props.login(this.state.email, this.state.password);
    event.preventDefault();
  }

  private renderLoginPage() {
    return (
      <Container component="main" maxWidth="xs" style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <img style={{ width: '35%' }} src={logo} alt="logo" />
          <Typography component="h1" variant="h4" style={{ fontFamily: 'Asap,sans-serif', fontWeight: 400, fontSize: '2.8rem', color: 'white' }}>
            <span style={{ fontWeight: 600 }}>waak</span>samen BackOffice
          </Typography>
        </Container>
        <Container style={{ display: 'flex', flexDirection: 'column', margin: '2rem', padding: '2rem', backgroundColor: 'white' }}>
          <form noValidate onSubmit={this.handleSubmit} method="POST">
            <Box m={1}>
              <TextField
                id="waaksamen-email-input"
                style={{ display: 'flex', flex: 1 }}
                label="Email adres"
                type="email"
                onChange={(e) => this.setState({ email: e.target.value })}
                autoComplete="current-email"
                error={this.state.error}
              />
            </Box>
            <Box m={1}>
              <TextField
                id="waaksamen-password-input"
                style={{ display: 'flex', flex: 1 }}
                label="Password"
                type="password"
                onChange={(e) => this.setState({ password: e.target.value })}
                autoComplete="current-password"
                error={this.state.error}
              />
            </Box>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Box mt={2}>
              <Button type="submit" fullWidth variant="contained" color="primary">
                Sign In
              </Button>
            </Box>
          </form>
          <Box mt={3}>
            <Copyright />
          </Box>
        </Container>
        <Snackbar />
      </Container>
    );
  }
}

export default connect((state: ApplicationState) => state.authentication, AuthenticationStoreStore.actionCreators)(withRouter(Login) as any);
