class AppSettings {
  ApiEndpoint: string;
  Environment: string;
  ClientRoot: string = 'http://localhost:3000/';
  Authority: string = 'https://localhost:44307/';
  ClientId: string = '176517BE829A46B1AFD8EFD75AA007F3.apps.vcsobservation.com';
  ClientScope: string = 'openid profile address email roles WaakSamen.Api';
  constructor() {
    const windowENV = (window as any).ENV;
    const processENV = process.env;
    console.log('WindowENV', windowENV);
    console.log('ProcessENV', processENV);
    this.Environment = windowENV && windowENV.REACT_APP_ENVIRONMENT ? windowENV.REACT_APP_ENVIRONMENT : processENV.REACT_APP_ENVIRONMENT;
    this.ApiEndpoint = windowENV && windowENV.REACT_APP_API_ENDPOINT ? windowENV.REACT_APP_API_ENDPOINT : processENV.REACT_APP_API_ENDPOINT;
  }
}

const Settings = new AppSettings();
export default Settings;
